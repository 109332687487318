import debounce from 'lodash.debounce';
import Popup from '../components/popup/_popup';

class BoardsOfDirectors {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.items = this.root.querySelectorAll('[data-bod-item]');

        return true;
    }

    init() {
        this.setItems();
    }

    setItems() {
        if (!this.items) return;

        this.onPopup = debounce(this.loadPopup.bind(this), 1000);
        this.items.forEach(item =>
            item.addEventListener('click', event => {
                this.loadPopup(event.currentTarget);
            })
        );
    }

    async loadPopup(target) {
        target.classList.add('-loading');

        const data = JSON.parse(target.getAttribute('data-bod-item'));
        if (!data) return;

        const formData = new FormData();
        formData.append('data', JSON.stringify(data));

        const request = await fetch(
            `${home_url}/wp-json/boards-of-directors/v1/popup`,
            {
                method: 'POST',
                body: formData,
            }
        );

        const res = await request.json();
        const { html } = res;
        document.body.insertAdjacentHTML('beforeend', html);

        this.popupEl = document.querySelector('[data-popup]');
        new Popup(this.popupEl);

        target.classList.remove('-loading');
    }
}

function initBoardsOfDirectors() {
    const sections = document.querySelectorAll('[data-bod]');
    if (!sections) return;

    sections.forEach(item => new BoardsOfDirectors(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initBoardsOfDirectors();
} else {
    document.addEventListener('DOMContentLoaded', initBoardsOfDirectors);
}
